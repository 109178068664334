import vovan from '../assets/images/merchants/vovan.png';
import chcmanual from '../assets/images/merchants/сhcmanual.png';
import lion from '../assets/images/merchants/lion.png';
import vocxod from '../assets/images/merchants/vocxod.svg';

export const getCurrencySymbol = (
  value: string | undefined
): string | undefined => {
  switch (value) {
    case 'RUB':
      return ' ₽';

    case 'UAH':
      return ' ₴';

    case 'AZN':
      return ' ₼';

    case 'BTC(BTC)':
      return ' ₿';

    default:
      return value;
  }
};

export const getCardNumberMask = (cardNumber: string | undefined) => {
  const regExp = /(\d{4})(\d{4})(\d{4})(\d{4})/gi;

  if (cardNumber?.match(regExp)?.length) {
    const masked = cardNumber.replace(regExp, (_, g1, g2, g3, g4) => {
      return `${g1} ${g2} ${g3} ${g4}`;
    });
    return masked;
  }
  return cardNumber;
};

export const getPhoneNumberMask = (phoneNumber: string | undefined) => {
  const regExp = /(\+7|8)\D*(\d{3})\D*(\d{3})\D*(\d{2})\D*(\d{2})/gi;

  if (phoneNumber) {
    const masked = phoneNumber.replace(regExp, (_, g1, g2, g3, g4, g5) => {
      return `+ 7 (${g2}) ${g3}-${g4}-${g5}`;
    });
    return masked;
  }

  return phoneNumber;
};

export const getMerchantLogo = (merchantName: string) => {
  switch (merchantName.toLowerCase()) {
    case 'vovan':
      return vovan;
    case 'lion':
      return lion;
    case 'сhcmanual':
      return chcmanual;
    case 'vocxod':
      return vocxod;
    default:
      break;
  }
};
