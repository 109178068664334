/* eslint-disable no-undef */
export const verboxLoader = (data, apiKey = 'b569279d86d4d56cf56b741d1fa84249') => {
  window.VerboxSetup = data.VerboxSetup;

  function load() {
    (function () {
      (function c(d, w, m, i) {
        window.supportAPIMethod = m;
        var s = d.createElement('script');
        s.id = 'supportScript';
        var id = apiKey;
        s.src =
          (!i ? 'https://admin.verbox.ru/support/support.js' : 'https://static.site-chat.me/support/support.int.js') +
          '?h=' +
          id;
        s.onerror = i
          ? undefined
          : function () {
              c(d, w, m, true);
            };
        w[m] =
          w[m] ||
          function () {
            (w[m].q = w[m].q || []).push(arguments);
          };
        (d.head || d.body).appendChild(s);
      })(document, window, 'Verbox');
    })();

    if (typeof data.clientInfo !== 'undefined') {
      Verbox('setClientInfo', data.clientInfo);
    }
  }

  document.addEventListener('click', function (event) {
    if (event.target.closest('.show-verbox')) {
      if (typeof Verbox !== 'function') {
        load();
        setTimeout(function () {
          Verbox('openTab');
        }, 1000);
        return;
      }
      Verbox('openTab');
    }
  });

  document.querySelectorAll('.soc-block__help, .mobile_butt_help').forEach(function (element) {
    element.addEventListener('click', function () {
      if (typeof Verbox === 'function') {
        return;
      }
      load();
    });
  });
};
