import clsx from 'clsx';
import React from 'react';
import styles from './Button.module.scss';

const Button = ({
  variant = 'primary',
  outlined,
  children,
  onClick,
  width,
}: {
  variant?: 'primary' | 'secondary' | 'success' | 'error';
  outlined?: boolean;
  children: React.ReactNode;
  onClick?: () => void;
  width?: string;
}) => {
  return (
    <button
      onClick={onClick}
      className={clsx(
        styles.wrapper,
        styles[variant],
        outlined && styles.outlined
      )}
      style={{ width: width ? width : '' }}
    >
      {children}
    </button>
  );
};

export default Button;
