import React, { FC } from 'react';
import styles from './RowFields.module.scss';
import clsx from 'clsx';

interface RowFieldsProps {
  direction: 'row' | 'column';
  children: React.ReactNode;
}

const RowFields: FC<RowFieldsProps> = ({ direction = 'row', children }) => {
  return (
    <div className={clsx(styles.wrapper, styles[direction])}>{children}</div>
  );
};

export default RowFields;
