import alfa from '../assets/images/alfa.svg';
import sber from '../assets/images/sber.svg';
import tinL from '../assets/images/tinkoff-light.svg';
import tinD from '../assets/images/tinkoff-dark.svg';
import rfs from '../assets/images/raiffeisen.svg';
import visaMir from '../assets/images/visamir.png';
import yoomoney from '../assets/images/yoomoney.svg';

export const renderImage = (paymentMethod?: string) => {
  const theme = document?.documentElement?.getAttribute('data-theme')

  if (paymentMethod?.toUpperCase() === 'SBER') {
    return sber;
  } else if (paymentMethod?.toUpperCase() === 'TINKOFF') {
    return theme === 'dark' ? tinL : tinD;
  } else if (paymentMethod?.toUpperCase() === 'RAIFFEISEN') {
    return rfs;
  } else if (paymentMethod?.toUpperCase() === 'MC/VISA UAH') {
    return visaMir;
  } else if (paymentMethod?.toUpperCase() === 'ALFABANK') {
    return alfa;
  } else if (paymentMethod?.toUpperCase() === 'YOOMONEY') {
    return yoomoney;
  } else {
    return;
  }
};
