import QRCode from 'qrcode.react';
import styles from './QR.module.scss';

const QR = ({ data }: { data: string }) => {
  return (
    <div className={styles.qr}>
      <QRCode size={100} value={data} />
    </div>
  );
};

export default QR;
