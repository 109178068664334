import { FC } from 'react';
import { getMerchantLogo } from '../../services/utils';
import crypto from '../../assets/images/crypto.svg';
import btc from '../../assets/images/crypto/coins/btc.svg';
import doge from '../../assets/images/crypto/coins/doge.svg';
import eth from '../../assets/images/crypto/coins/eth.svg';
import ltc from '../../assets/images/crypto/coins/ltc.svg';
import not from '../../assets/images/crypto/coins/not.svg';
import ton from '../../assets/images/crypto/coins/ton.svg';
import usdc from '../../assets/images/crypto/coins/usdc.svg';
import usdt from '../../assets/images/crypto/coins/usdt.svg';
import erc20 from '../../assets/images/erc20.svg';
import trc20 from '../../assets/images/trc20.svg';
import { Transaction } from '../../services/types';
import Container from '../../components/atoms/Container/Container';
import Field from '../../components/atoms/Field/Field';
import InfoBlock from '../../components/organisms/InfoBlock/InfoBlock';
import Button from '../../components/atoms/Button/Button';
import styles from './CryptoPage.module.scss';
import RowFields from '../../components/atoms/RowFields/RowFields';
import Labeled from '../../components/atoms/Labeled/Labeled';
import QR from '../../components/atoms/QR/QR';
import SupportButton from '../../components/atoms/SupportButton/SupportButton';
import Header from '../../components/organisms/Header/Header';

interface CryptoPageProps {
  transaction: Transaction;
  timeLeft: string;
  ticketId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const CryptoPage: FC<CryptoPageProps> = ({
  transaction,
  timeLeft,
  ticketId,
  onConfirm,
  onCancel,
}) => {
  const { requisite, isRedirectUrlExist } = transaction;

  const getNetworkData = (networkName: string) => {
    switch (networkName) {
      case 'BTC':
        return { img: btc, text: 'BTC' };
      case 'ETH':
        return { img: eth, text: 'ETH' };
      case 'ERC20':
        return { img: erc20, text: 'ERC20' };
      case 'TRX':
        return { img: trc20, text: 'TRC20' };
      case 'TON':
        return { img: ton, text: 'TON' };
      case 'LTC':
        return { img: ltc, text: 'LTC' };
      case 'DOGE':
        return { img: doge, text: 'DOGE' };
      default:
        break;
    }
  };

  const getCoinImage = (coinName: string) => {
    switch (coinName) {
      case 'BTC(BTC)':
        return btc;
      case 'ETH(ETH)':
        return eth;
      case 'LTC(LTC)':
        return ltc;
      case 'NOT(TON)':
        return not;
      case 'TON(TON)':
        return ton;
      case 'USDT(TON)':
        return usdt;
      case 'USDT(TRX)':
        return usdt;
      case 'USDC(ETH)':
        return usdc;
      case 'DOGE(DOGE)':
        return doge;
      default:
        break;
    }
  };

  return (
    <>
      <Header
        transaction={transaction}
        ticketId={ticketId}
        timeLeft={timeLeft}
      />
      {transaction.merchant.toLowerCase() === 'vocxod' && (
        <div style={{ marginBottom: '28px' }}>
          <SupportButton userId={transaction.externalUserId} />
        </div>
      )}
      <Container>
        <div className={styles.imageWrapper}>
          {getMerchantLogo(transaction.merchant) ? (
            <img
              width={85}
              src={getMerchantLogo(transaction.merchant)}
              alt='лого мерчанта'
            />
          ) : (
            <h3>{transaction.merchant.toLocaleUpperCase()}</h3>
          )}
        </div>
        <div className={styles.fieldGroup}>
          <Field image={crypto} text={'Криптовалюта'} />

          <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <RowFields direction={requisite.qrCodeData ? 'column' : 'row'}>
              <Labeled>
                <span>Монета</span>
                <Field
                  image={getCoinImage(transaction.requisite.currency)}
                  text={
                    transaction.requisite.currency?.split('(')[0] ||
                    transaction.requisite.currency
                  }
                />
              </Labeled>

              <Labeled>
                <span>Сеть</span>
                <Field
                  image={getNetworkData(transaction.requisite.network)?.img}
                  text={getNetworkData(transaction.requisite.network)?.text}
                />
              </Labeled>
            </RowFields>
            {requisite.qrCodeData && (
              <Labeled>
                <p>QR-код</p>
                <QR data={requisite.qrCodeData} />
              </Labeled>
            )}
          </div>
          {!isRedirectUrlExist && (
            <Labeled>
              <span>Адрес кошелька</span>
              <Field copy copyType='full' text={transaction.requisite.wallet} />
            </Labeled>
          )}
          {transaction?.requisite?.country &&
            transaction.requisite?.country !== ' ' && (
              <Labeled>
                <span>Страна</span>
                <Field text={transaction?.requisite?.country} />
              </Labeled>
            )}
          {transaction?.requisite?.userManual && (
            <InfoBlock data={transaction?.requisite?.userManual} />
          )}
          <div className={styles.buttonGroup}>
            <Button variant='secondary' onClick={onCancel}>
              Отмена
            </Button>
            <Button onClick={onConfirm} variant='primary'>
              {isRedirectUrlExist ? 'Перейти к оплате' : 'Оплатил'}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default CryptoPage;
