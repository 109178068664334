import { Dispatch, MouseEvent, SetStateAction, useRef, useState } from 'react';
import { Transaction } from '../../../services/types';
import styles from './Header.module.scss';
import shield from '../../../assets/images/shield.svg';
import copyImage from '../../../assets/images/copyBlue.svg';
import success from '../../../assets/images/borderless-success.svg';

const Header = ({
  transaction,
  ticketId,
  timeLeft,
  disableTimer = false,
}: {
  transaction: Transaction;
  ticketId: string;
  timeLeft: string;
  disableTimer?: boolean;
}) => {
  const [copiedTransaction, setCopiedTransaction] = useState(false);
  const [copiedUserId, setCopiedUserId] = useState(false);
  const transactionRef = useRef<HTMLSpanElement | null>(null);
  const userIdRef = useRef<HTMLSpanElement | null>(null);
  const { isRedirectUrlExist } = transaction;

  const handleCopy = (
    e: MouseEvent<HTMLParagraphElement>,
    callback: Dispatch<SetStateAction<boolean>>,
    type: 'transaction' | 'user'
  ) => {
    console.log(type);
    navigator.clipboard
      .writeText(
        (type === 'transaction'
          ? transactionRef?.current?.innerText
          : userIdRef?.current?.innerText) || ''
      )
      .then(() => {
        callback(true);

        setTimeout(() => {
          callback(false);
        }, 3000);
      })
      .catch((err) => {
        console.error('Failed to copy text: ', err);
      });
  };

  return (
    <div className={styles.headerWrapper}>
      <div className={styles.headerGroup}>
        <div className={styles.title}>
          <img src={shield} alt='shield' />
          <h3>Пополнение</h3>
        </div>

        {!isRedirectUrlExist && !disableTimer ? (
          <p className={styles.timer}>{timeLeft}</p>
        ) : (
          <p></p>
        )}
      </div>
      <div className={styles.row}>
        <p>ID транзакции:</p>
        <div className={styles.blob} />
        <div className={styles.content}>
          <p className={styles.overflowText}>
            <span ref={transactionRef}>{ticketId}</span>
          </p>
          <div className={styles.copy}>
            {!copiedTransaction && (
              <img
                style={{ objectFit: 'cover' }}
                src={copyImage}
                alt='copy'
                onClick={(e) =>
                  handleCopy(e, setCopiedTransaction, 'transaction')
                }
                width={12}
                height={12}
              />
            )}
            {copiedTransaction && (
              <img src={success} alt='success' width={12} height={12} />
            )}
          </div>
        </div>
      </div>
      {transaction?.externalUserId && (
        <div className={styles.row}>
          <p>ID пользователя:</p>
          <div className={styles.blob} />
          <div className={styles.content}>
            <p className={styles.overflowText}>
              <span ref={userIdRef}>{transaction?.externalUserId}</span>
            </p>
            <div className={styles.copy}>
              {!copiedUserId && (
                <img
                  style={{ objectFit: 'cover' }}
                  src={copyImage}
                  alt='copy'
                  onClick={(e) => handleCopy(e, setCopiedUserId, 'user')}
                  width={12}
                  height={12}
                />
              )}
              {copiedUserId && (
                <img src={success} alt='success' width={12} height={12} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Header;
