import { FC } from 'react';
import sbp from '../../assets/images/sbp.png';
import { getMerchantLogo, getPhoneNumberMask } from '../../services/utils';
import { Transaction } from '../../services/types';
import { renderImage } from '../../utils/renderImage';
import Header from '../../components/organisms/Header/Header';
import Field from '../../components/atoms/Field/Field';
import InfoBlock from '../../components/organisms/InfoBlock/InfoBlock';
import Button from '../../components/atoms/Button/Button';
import Container from '../../components/atoms/Container/Container';
import RowFields from '../../components/atoms/RowFields/RowFields';
import styles from './SbpPage.module.scss';
import Labeled from '../../components/atoms/Labeled/Labeled';
import QR from '../../components/atoms/QR/QR';
import SupportButton from '../../components/atoms/SupportButton/SupportButton';

interface SbpPageProps {
  transaction: Transaction;
  timeLeft: string;
  ticketId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const SbpPage: FC<SbpPageProps> = ({
  transaction,
  ticketId,
  onCancel,
  onConfirm,
  timeLeft,
}) => {
  const { requisite, isRedirectUrlExist, amount } = transaction;

  const requesiteTitle = (type: string | undefined) => {
    switch (type) {
      case 'bank_account':
        return 'Номер счета';
      case 'bank_card':
        return 'Номер карты';
      case 'crypto_wallet':
        return 'Номер кошелька';
      case 'phone_number':
        return 'Номер телефона';
      default:
        return 'Номер реквизита';
    }
  };

  return (
    <>
      <Header
        transaction={transaction}
        ticketId={ticketId}
        timeLeft={timeLeft}
      />

      {transaction.merchant.toLowerCase() === 'vocxod' && (
        <div style={{ marginBottom: '28px' }}>
          <SupportButton userId={transaction.externalUserId} />
        </div>
      )}
      <Container>
        <div className={styles.imageWrapper}>
          {getMerchantLogo(transaction.merchant) ? (
            <img
              width={85}
              src={getMerchantLogo(transaction.merchant)}
              alt='лого мерчанта'
            />
          ) : (
            <h3>{transaction.merchant.toLocaleUpperCase()}</h3>
          )}
        </div>
        <div className={styles.fieldGroup}>
          <Field
            image={sbp}
            bold
            text={
              requisite.qrCodeData
                ? 'Пополнение через СБП + QR'
                : 'Пополнение через СБП'
            }
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <RowFields direction={requisite.qrCodeData ? 'column' : 'row'}>
              {amount && (
                <Labeled width='100%'>
                  <span>Сумма перевода</span>
                  <Field text={amount} copy currency='₽' />
                </Labeled>
              )}

              <Labeled width='100%'>
                <span>Банк</span>
                {renderImage(requisite.acquiringBank) ? (
                  <Field image={renderImage(requisite.acquiringBank)} />
                ) : (
                  <Field
                    text={
                      requisite?.acquiringBank.length > 15
                        ? requisite?.acquiringBank?.toUpperCase().slice(0, 17) +
                          '...'
                        : requisite?.acquiringBank?.toUpperCase()
                    }
                  />
                )}
              </Labeled>
            </RowFields>
            {requisite.qrCodeData && (
              <Labeled>
                <p>QR-код</p>
                <QR data={requisite.qrCodeData} />
              </Labeled>
            )}
          </div>
          {!isRedirectUrlExist && (
            <Labeled>
              <span>{requesiteTitle('phone_number')}</span>
              <Field
                copy
                copyType='phone'
                text={getPhoneNumberMask(requisite?.phone)}
              />
            </Labeled>
          )}
          {transaction?.requisite?.country &&
            transaction.requisite?.country !== ' ' && (
              <Labeled>
                <span>Страна перевода</span>
                <Field text={transaction?.requisite?.country} />
              </Labeled>
            )}
          <Labeled>
            <span>Получатель</span>
            <Field text={requisite?.name} />
          </Labeled>
          {transaction?.requisite?.userManual && (
            <Labeled>
              <span>Инструкция</span>
              <InfoBlock data={transaction?.requisite?.userManual} />
            </Labeled>
          )}
          <div className={styles.buttonGroup}>
            <Button variant='secondary' onClick={onCancel}>
              Отмена
            </Button>
            <Button onClick={onConfirm} variant='primary'>
              {isRedirectUrlExist ? 'Перейти к оплате' : 'Оплатил'}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default SbpPage;
