import { verboxLoader } from '../verboxLoader';

const useVerbox = () => {
  const initVerbox = (userId: string | undefined) => {
    if (userId) {
      verboxLoader({
        VerboxSetup: { clientId: userId },
        clientInfo: {
          clientId: userId,
        },
      });
    } else {
      verboxLoader({
        VerboxSetup: { clientId: userId },
      });
    }
  };

  return { initVerbox };
};

export default useVerbox;
