import React, { useEffect } from 'react';
import success from '../../assets/images/success-logo.svg';
import Button from '../../components/atoms/Button/Button';
import styles from './Success.module.scss';
import SupportButton from '../../components/atoms/SupportButton/SupportButton';
import { Transaction } from '../../services/types';

interface Props {
  transaction: Transaction;
  onRedirect: () => void;
}

const Success: React.FC<Props> = ({ onRedirect, transaction }) => {
  useEffect(() => {
    const timer = setTimeout(onRedirect, 5000);
    return () => clearTimeout(timer);
  }, [onRedirect]);

  return (
    <>
      <section className={styles.wrapper}>
        {transaction.merchant.toLowerCase() === 'сhcmanual' && (
          <SupportButton userId={transaction.externalUserId} />
        )}
        <div className={styles.container}>
          <img src={success} alt='success' width={80} height={80} />
          <p>Ваш платеж будет зачислен в&nbsp;течение нескольких минут</p>
          <Button onClick={onRedirect} variant='success'>
            Оk
          </Button>
        </div>
      </section>
    </>
  );
};

export default Success;
