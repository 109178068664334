import { FC, useRef, useState } from 'react';
import copyImage from '../../../assets/images/copy.svg';
import success from '../../../assets/images/success.svg';

import styles from './Field.module.scss';
import clsx from 'clsx';

interface FieldProps {
  image?: string;
  text?: string | number;
  copy?: boolean;
  currency?: string;
  bold?: true;
  copyType?: string;
  border?: string;
  center?: boolean;
  width?: string;
  fontSize?: string;
}

const Field: FC<FieldProps> = ({
  image,
  text,
  copy,
  currency,
  bold,
  copyType,
  border,
  center = false,
  width,
  fontSize,
}) => {
  const [copied, setCopied] = useState(false);
  const textRef = useRef<HTMLSpanElement | null>(null);

  const handleCopy = () => {
    if (textRef.current && textRef.current.innerText) {
      let value;

      if (copyType === 'full') {
        value = textRef.current.innerText;
      } else {
        const numericValue =
          textRef.current.innerText.match(/\d+(\.\d+)?/gm) || '';
        value = [...numericValue].join('');
      }

      navigator.clipboard
        .writeText(value)
        .then(() => {
          setCopied(true);

          setTimeout(() => {
            setCopied(false);
          }, 3000);
        })
        .catch((err) => {
          console.error('Failed to copy text: ', err);
        });
    }
  };

  return (
    <>
      <div className={styles.wrapper} style={{ width: width ? width : '' }}>
        <div
          className={clsx(
            styles.field,
            !copy && styles.fullBorder,
            bold && styles.bold,
            copy && styles.copyWidth,
            center && styles.center
          )}
          style={{
            border: border ? `1px solid ${border}` : 'none',
            fontSize: fontSize ? fontSize : '',
          }}
        >
          {image && (
            <img
              src={image}
              alt={text?.toString()}
              height={25}
              width={'auto'}
            />
          )}
          {text !== null || text !== undefined ? (
            <span className={styles.text} ref={textRef}>
              {currency ? text + ` ${currency}` : text}
            </span>
          ) : null}
        </div>
        {copy && (
          <div className={styles.copy} onClick={handleCopy}>
            {copied ? (
              <img src={success} alt='success' width={20} height={20} />
            ) : (
              <img src={copyImage} alt='copy' width={20} height={20} />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default Field;
