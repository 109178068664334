import { FC } from 'react';
import {
  getCardNumberMask,
  getCurrencySymbol,
  getMerchantLogo,
} from '../../services/utils';
import { Transaction } from '../../services/types';
import card from '../../assets/images/card.svg';
import { renderImage } from '../../utils/renderImage';
import InfoBlock from '../../components/organisms/InfoBlock/InfoBlock';
import Container from '../../components/atoms/Container/Container';
import Field from '../../components/atoms/Field/Field';
import styles from './P2PPage.module.scss';
import RowFields from '../../components/atoms/RowFields/RowFields';
import QR from '../../components/atoms/QR/QR';
import Labeled from '../../components/atoms/Labeled/Labeled';
import SupportButton from '../../components/atoms/SupportButton/SupportButton';
import Header from '../../components/organisms/Header/Header';
import Button from '../../components/atoms/Button/Button';

interface P2PPageProps {
  transaction: Transaction;
  timeLeft: string;
  ticketId: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const P2PPage: FC<P2PPageProps> = ({
  transaction,
  ticketId,
  onCancel,
  onConfirm,
  timeLeft,
}) => {
  const { requisite, isRedirectUrlExist, amount } = transaction;

  const currencySymbol = getCurrencySymbol(requisite.currency);

  const requesiteTitle = (type: string | undefined) => {
    switch (type) {
      case 'bank_account':
        return 'Номер счета';
      case 'bank_card':
        return 'Номер карты';
      case 'crypto_wallet':
        return 'Номер кошелька';
      case 'phone_number':
        return 'Номер телефона';
      default:
        return 'Номер реквизита';
    }
  };

  return (
    <>
      <Header
        transaction={transaction}
        ticketId={ticketId}
        timeLeft={timeLeft}
      />
      {transaction.merchant.toLowerCase() === 'vocxod' && (
        <div style={{ marginBottom: '28px' }}>
          <SupportButton userId={transaction.externalUserId} />
        </div>
      )}
      <Container>
        <div className={styles.imageWrapper}>
          {getMerchantLogo(transaction.merchant) ? (
            <img
              width={85}
              src={getMerchantLogo(transaction.merchant)}
              alt='лого мерчанта'
            />
          ) : (
            <h3>{transaction.merchant.toLocaleUpperCase()}</h3>
          )}
        </div>
        <div className={styles.fieldGroup}>
          <Field image={card} text={'По номеру карты'} />

          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <RowFields direction={requisite?.qrCodeData ? 'column' : 'row'}>
              {amount && (
                <Labeled width='100%'>
                  <span>Сумма перевода</span>
                  <Field text={amount} copy currency={currencySymbol} />
                </Labeled>
              )}

              {requisite?.acquiringBank && (
                <Labeled width='100%'>
                  <span>Банк</span>
                  {renderImage(requisite?.acquiringBank) ? (
                    <Field image={renderImage(requisite?.acquiringBank)} />
                  ) : (
                    <Field
                      text={
                        requisite?.acquiringBank.length > 15
                          ? requisite?.acquiringBank
                              ?.toUpperCase()
                              .slice(0, 17) + '...'
                          : requisite?.acquiringBank?.toUpperCase()
                      }
                    />
                  )}
                </Labeled>
              )}
            </RowFields>
            {requisite.qrCodeData && (
              <Labeled>
                <p>QR-код</p>
                <QR data={requisite.qrCodeData} />
              </Labeled>
            )}
          </div>
          {!isRedirectUrlExist && (
            <Labeled>
              <span>{requesiteTitle(transaction.requisite.type)}</span>
              <Field
                copy
                copyType='clean'
                text={getCardNumberMask(transaction.requisite.cardNumber)}
              />
            </Labeled>
          )}
          {transaction?.requisite?.country &&
            transaction.requisite?.country !== ' ' && (
              <Labeled>
                <span>Страна</span>
                <Field text={transaction?.requisite?.country} />
              </Labeled>
            )}
          {transaction?.requisite?.name && (
            <Labeled>
              <span>Получатель</span>
              <Field text={transaction?.requisite?.name} />
            </Labeled>
          )}
          {transaction?.requisite?.userManual && (
            <InfoBlock data={transaction?.requisite?.userManual} />
          )}
          <div className={styles.buttonGroup}>
            <Button variant='secondary' onClick={onCancel}>
              Отмена
            </Button>
            <Button onClick={onConfirm} variant='primary'>
              {isRedirectUrlExist ? 'Перейти к оплате' : 'Оплатил'}
            </Button>
          </div>
        </div>
      </Container>
    </>
  );
};

export default P2PPage;
