import styles from './Labeled.module.scss';

const Labeled = ({
  children,
  width,
}: {
  children: React.ReactNode;
  width?: string;
}) => {
  return (
    <div style={{ width: width }} className={styles.labeled}>
      {children}
    </div>
  );
};

export default Labeled;
