import clsx from 'clsx';
import supportIcon from '../../../assets/images/support.svg';
import styles from './SupportButton.module.scss';
import useVerbox from '../../../hooks/useVerbox';


const SupportButton = ({ userId }: { userId?: string }) => {
  const { initVerbox } = useVerbox();

  return (
    <button
      className={clsx(styles.wrapper, styles['primary'], 'show-verbox')}
      onClick={() => initVerbox(userId)}
    >
      <img src={supportIcon} alt='support_icon' />
      <p>Нужна помощь?</p>
    </button>
  );
};

export default SupportButton;
