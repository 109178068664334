import styles from './InfoBlock.module.scss';

const InfoBlock = ({ data }: { data: string | null }) => {
  return data && data?.length > 1 ? (
    <div
      className={styles.wrapper}
      dangerouslySetInnerHTML={{ __html: data }}
    />
  ) : (
    <></>
  );
};

export default InfoBlock;
