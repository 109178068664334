import { FC } from 'react';
import loader from '../assets/images/loader.svg';
import styles from './Spinner.module.scss';

interface SpinnerProps {
  waitingForRequisite?: boolean;
}

const Spinner: FC<SpinnerProps> = ({ waitingForRequisite }) => {
  return (
    <div className='loader'>
      <img src={loader} alt='loader' />
      {waitingForRequisite && (
        <p className={styles.text}>Ожидание реквизитов...</p>
      )}
    </div>
  );
};

export default Spinner;
